<template>
  <button class="button is-small" @click="toggle">
    {{ pret ? $t('sketchy.page_jeu.player_list.pas_pret') : $t('sketchy.page_jeu.player_list.pret') }}
  </button>
</template>

<script>
export default {
  name: 'E0BoutonPret',

  data() {
    return {
    };
  },

  methods: {
    toggle() {
      this.$emit('click');
    },
  },

  props: ['pret'],
}
</script>
